<template>
  <div>
    <station @confirmstation="confirmstation"></station>
  </div>
</template>
<script>
import station from "../components/station/index.vue"
export default {
  components:{
    station
  },
  methods:{
    confirmstation(e){
      localStorage.setItem("intention2", e);
      this.$router.go(-1);
    }
  }
}
</script>
<style scoped>

</style>